@import './globalColorVars.scss';
@import './font-family.scss';

span.text-divider {
  color: $border-divider-color !important;
  padding: 0 6px;
}

// This sets a hover opacity if we have a user color from the API & added to context
// this class is added if the context var returns true
.has-user-color:hover,
.has-user-color:focus {
  opacity: 0.8;
}

.bt-button:disabled {
  background-color: $button-primary-disabled-color !important;
  color: $button-primary-focus-color !important;
  border: 1px solid $buttons-bright-border !important;
  opacity: 0.5;
}

.filter-buttons {
  border: 1px solid $border-color-bright;
  background-color: $bt-white;
  border-radius: 3px;
  color: $neutral-text;
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  height: 40px;

  &:hover {
    background-color: $button-neutral-hover-color;
    color: $primary-text;
    border-color: $border-color-bright;
  }

  &:focus,
  &:active {
    background-color: $button-neutral-focus-color;
    color: $primary-text;
  }

  &.selected {
    background-color: $button-neutral-hover-color;
    color: $primary-text;
  }
}

.single-icon-button {
  @extend .filter-buttons;
  background-color: transparent;
  padding: 12px 20px;
}

.active {
  background-color: $background-grey;
}

.text-link-primary {
  color: $button-primary-color;
  @include roboto-regular;

  &:hover {
    color: $button-primary-hover-color;
  }

  &:focus {
    color: $button-primary-focus-color;
  }

  &.sml {
    font-size: 14px;
  }

  &.med {
    font-size: 16px;
  }

  &.lrg {
    font-size: 20px;
  }
}

.text-link-secondary {
  color: $button-secondary-color;
  @include roboto-regular;

  &:hover {
    color: $button-primary-hover-color;
  }

  &:focus {
    color: $button-primary-focus-color;
  }

  &.sml {
    font-size: 14px;
  }

  &.med {
    font-size: 16px;
  }

  &.lrg {
    font-size: 20px;
  }
}

// ===== BUILDING BLOCK RULESTS
.bt-generic-styles {
  border-radius: 3px !important;
  border-width: 1px !important;
  border-style: solid !important;
  @include roboto-regular;
  text-align: center;

  // allow the button width to expand to hold words horizontally, padding set to font size
  &.bt-elastic {
    display: inline-block;
    height: auto;
    width: auto;
    padding: 0.55rem 1.2rem !important;
  }
  // Allow horizontal words fixed padding
  &.bt-fluid {
    display: inline-block;
    //height: auto;
    width: auto;
    //padding: 12px 20px !important;
  }

  > svg {
    margin-right: 6px;
  }
}

.bt-btn-style-primary-color {
 color: $bt-white !important;
 // if series page has set a css var use it else drop back to default color
 background: var(--series-primary-color, $button-primary-color) !important;
 border-color: var(--series-primary-color, $button-primary-color) !important;

  &:hover {
    background-color: $button-primary-hover-color;
    border-color: $button-primary-hover-color;
  }
}

.bt-btn-style-secondary-color {
  background: $bt-white;
  color: $button-secondary-color;
  border-color: $button-secondary-color;

  svg {
    color: $button-primary-color;
  }

  &:hover {
    background-color: $button-secondary-hover-color;
    color: $button-primary-color;
    border-color: $button-primary-color;
  }

  &:focus,
  &:active {
    background-color: $button-secondary-hover-color;
    border-color: $button-secondary-focus-color;
    color: $button-secondary-focus-color;

    svg {
      color: $button-secondary-focus-color;
    }
  }
}

.bt-btn-style-tertiary-color {
  background: $bt-white;
  color: $button-secondary-color;
  border-color: $button-secondary-color;

  svg {
    color: $button-primary-color;
  }

  &:hover {
    background-color: $button-secondary-hover-color;
    color: $button-primary-color;
    border-color: $button-primary-color;
  }

  &:focus,
  &:active {
    background-color: $button-secondary-hover-color;
    border-color: $button-secondary-focus-color;
    color: $button-secondary-focus-color;

    svg {
      color: $button-secondary-focus-color;
    }
  }
}

.bt-btn-style-bright-color {
  color: $neutral-text;
  background: $button-neutral-color;
  border-color: $border-color-bright;

  &:hover {
    background-color: $button-neutral-hover-color;
  }

  &:focus,
  &:active {
    color: $button-primary-focus-color;

    svg {
      color: $button-primary-focus-color;
    }
  }

  svg {
    color: $neutral-text;
  }
}

@mixin psuedoStates(
  $focusColor,
  $hoverColor,
  $activeClass,
  $borderColor: borderColor
) {
  &:focus {
    background: var(--series-primary-color, $focusColor) !important;
    border-color: var(--series-primary-color, $focusColor) !important;
  }

  &:hover {
    background: var(--series-primary-color, $hoverColor);

    @if $borderColor {
      border-color: var(--series-primary-color, $borderColor) !important;
    } @else {
      border-color: var(--series-primary-color, $hoverColor) !important;
    }
  }

  &.active {
    background: var(--series-primary-color, $activeClass) !important;
  }
}

// disabled states == opacity 0.6

// ==== WORKING RULESETS ====
// USE THESE CLASSES TO STYLE BUTTON ELEMENTS E.G STYLES['.BT-BUTTON-PRIMARY-WIDE']

/** ===  GREEN Full width button/bar  3 sizes ==== */

.bt-button-lrg-primary-wide {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-primary-color;
  width: 100%;
  font-size: 20px;

 @include psuedoStates(
  $button-primary-focus-color,
  $button-primary-hover-color,
  $button-primary-active-color
 )

}

.bt-button-regular-primary-wide {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-primary-color;
  width: 100%;
  font-size: 16px;

  @include psuedoStates(
    $button-primary-focus-color,
    $button-primary-hover-color,
    $button-primary-active-color
  );
}

.bt-button-small-primary-wide {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-primary-color;
  width: 100%;
  font-size: 14px;

  @include psuedoStates(
    $button-primary-focus-color,
    $button-primary-hover-color,
    $button-primary-active-color
  );
}

/** === GREEN Short buttons 3 sizes ==== */
.bt-button-lrg-primary-short {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-primary-color;
  width: 98px;
  height: 48px;
  font-size: 20px;

  @include psuedoStates(
    $button-primary-focus-color,
    $button-primary-hover-color,
    $button-primary-active-color,
    $button-primary-hover-color
  );
}

.bt-button-regular-primary-short {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-primary-color;
  width: 98px;
  height: 48px;
  font-size: 16px;

  @include psuedoStates(
    $button-primary-focus-color,
    $button-primary-hover-color,
    $button-primary-active-color,
    $button-primary-hover-color
  );
}

.bt-button-small-primary-short {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-primary-color;
  width: 98px;
  height: 48px;
  font-size: 14px;

  @include psuedoStates(
    $button-primary-focus-color,
    $button-primary-hover-color,
    $button-primary-active-color
  );
}

/** ===  GREY (secondary) Full width button/bar  3 sizes ==== */

.bt-button-lrg-secondary-wide {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-secondary-color;
  width: 100%;
  font-size: 20px;

  @include psuedoStates(
    $button-secondary-focus-color,
    $button-secondary-hover-color,
    $button-secondary-active-color
  );
}

.bt-button-regular-secondary-wide {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-secondary-color;
  width: 100%;
  font-size: 16px;

  @include psuedoStates(
    $button-secondary-focus-color,
    $button-secondary-hover-color,
    $button-secondary-active-color
  );
}

.bt-button-small-secondary-wide {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-secondary-color;
  width: 100%;
  font-size: 14px;

  @include psuedoStates(
    $button-secondary-focus-color,
    $button-secondary-hover-color,
    $button-secondary-active-color
  );
}

/** === GREY (secondary) Short buttons 3 sizes ==== */
.bt-button-lrg-secondary-short {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-secondary-color;
  width: 98px;
  height: 48px;
  font-size: 20px;

  @include psuedoStates(
    $button-secondary-focus-color,
    $button-secondary-hover-color,
    $button-secondary-active-color
  );
}

.bt-button-regular-secondary-short {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-secondary-color;
  width: 98px;
  height: 48px;
  font-size: 16px;

  @include psuedoStates(
    $button-secondary-focus-color,
    $button-secondary-hover-color,
    $button-secondary-active-color
  );
}

.bt-button-small-secondary-short {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-secondary-color;
  width: 98px;
  height: 48px;
  font-size: 14px;

  @include psuedoStates(
    $button-secondary-focus-color,
    $button-secondary-hover-color,
    $button-secondary-active-color
  );
}

/** ===  Light GREY (tertiary) wide button/bar  3 sizes ==== */

.bt-button-lrg-tertiary-wide {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-tertiary-color;
  width: 100%;
  font-size: 20px;

  @include psuedoStates(
    $button-tertiary-focus-color,
    $button-tertiary-hover-color,
    $button-tertiary-active-color
  );
}

.bt-button-regular-tertiary-wide {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-secondary-color;
  width: 100%;
  font-size: 16px;

  @include psuedoStates(
    $button-tertiary-focus-color,
    $button-tertiary-hover-color,
    $button-tertiary-active-color
  );
}

.bt-button-small-tertiary-wide {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-tertiary-color;
  width: 100%;
  font-size: 14px;

  @include psuedoStates(
    $button-tertiary-focus-color,
    $button-tertiary-hover-color,
    $button-tertiary-active-color
  );
}

/** ===  Light GREY (tertiary) short button  3 sizes ==== */

.bt-button-lrg-tertiary-short {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-tertiary-color;
  width: 98px;
  height: 48px;
  font-size: 20px;

  @include psuedoStates(
    $button-tertiary-focus-color,
    $button-tertiary-hover-color,
    $button-tertiary-active-color
  );
}

.bt-button-regular-tertiary-short {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-tertiary-color;
  width: 98px;
  height: 48px;
  font-size: 16px;

  @include psuedoStates(
    $button-tertiary-focus-color,
    $button-tertiary-hover-color,
    $button-tertiary-active-color
  );
}

.bt-button-small-tertiary-short {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-tertiary-color;
  width: 98px;
  height: 48px;
  font-size: 14px;

  @include psuedoStates(
    $button-tertiary-focus-color,
    $button-tertiary-hover-color,
    $button-tertiary-active-color
  );
}

/** ===  Bright ( no background border only ) wide button/bar  3 sizes ==== */

.bt-button-lrg-bright-wide {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-bright-color;
  width: 100%;
  font-size: 20px;

  @include psuedoStates(
    $button-bright-focus-color,
    $button-bright-hover-color,
    $button-bright-active-color,
    $border-color-bright
  );
}

.bt-button-regular-bright-wide {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-bright-color;
  width: 100%;
  font-size: 16px;

  @include psuedoStates(
    $button-bright-focus-color,
    $button-bright-hover-color,
    $button-bright-active-color,
    $border-color-bright
  );
}

.bt-button-small-bright-wide {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-bright-color;
  width: 100%;
  font-size: 14px;

  @include psuedoStates(
    $button-bright-focus-color,
    $button-bright-hover-color,
    $button-bright-active-color,
    $border-color-bright
  );
}

/** ===  Bright ( no background border only ) short button  3 sizes ==== */

.bt-button-lrg-bright-short {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-bright-color;
  width: 98px;
  height: 48px;
  font-size: 20px;

  @include psuedoStates(
    $button-bright-focus-color,
    $button-bright-hover-color,
    $button-bright-active-color,
    $border-color-bright
  );
}

.bt-button-regular-bright-short {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-bright-color;
  width: 98px;
  height: 48px;
  font-size: 16px;

  @include psuedoStates(
    $button-bright-focus-color,
    $button-bright-hover-color,
    $button-bright-active-color,
    $border-color-bright
  );
}

.bt-button-small-bright-short {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-bright-color;
  width: 98px;
  //height: 40px;
  font-size: 14px;

  &:hover {
    color: $primary-text;
  }

  @include psuedoStates(
    $button-bright-focus-color,
    $button-bright-hover-color,
    $button-bright-active-color,
    $border-color-bright
  );
}
.bt-button-small-bright-active {
  background-color: $button-bright-active-color;
  background: $button-bright-active-color;
}
/** === Fluid width buttons - takes width from text, padding is in EM's for elastic sizing ==== */

// Not used currently refere to classes top of file for inline buttons
.bt-button-large-primary-fluid {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-primary-color;

  font-size: 20px !important;
  display: inline-block !important;
  padding: 0.75em 1.25em !important;

  @include psuedoStates(
    $button-primary-focus-color,
    $button-primary-hover-color,
    $button-primary-active-color
  );
}

.bt-button-regular-primary-fluid {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-primary-color;

  font-size: 16px !important;
  display: inline-block !important;
  padding: 0.75em 1.25em !important;

  @include psuedoStates(
    $button-primary-focus-color,
    $button-primary-hover-color,
    $button-primary-active-color
  );
}

.bt-button-small-primary-fluid {
  @extend .bt-generic-styles;
  @extend .bt-btn-style-primary-color;

  font-size: 14px !important;
  display: inline-block !important;
  padding: 0.75em 1.25em !important;

  @include psuedoStates(
    $button-primary-focus-color,
    $button-primary-hover-color,
    $button-primary-active-color
  );
}

