
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '../../style/container.scss';
@use '../../style/icons.scss';
@import '../../style/font-family.scss';
@import '../../style/globalColorVars.scss';
@import '../../style/globalVars.scss';
@import '../../style/buttons.scss';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@include media-breakpoint-up(md) {
  .banner-container {
    width: 445px;
  }
}

.banners {
  background: transparent
    linear-gradient(90deg, $really-light-grey 0%, $bt-white 100%) 0% 0% no-repeat
    padding-box;
  width: 100%;
  position: relative;
  opacity: 1;
  margin-top: -34px;
  box-shadow: 0 0 3px $box-shadow-color;

  div {
    position: relative;
  }

  h1 {
    font-size: 36px;
    @include roboto-medium;
  }

  p {
    font-size: 18px;
    @include roboto-light;
    margin-bottom: 0;
  }

  .hero-mask {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }
}

.homepage-banner {
	background: transparent
	  linear-gradient(90deg, $really-light-grey 0%, $button-primary-focus-color 100%) 0% 0% no-repeat
	  padding-box;
	width: 100%;
	position: relative;
	opacity: 1;
	margin-top: -34px;
	box-shadow: 0 0 3px $box-shadow-color;
}

.page-banner {
  min-height: 169px;
  padding: 30px 0;
  margin-bottom: 30px;

  &:has(.breadcrumbs) {
    padding: 0 0 33px 0;
  }

  .hero-mask {
    background: transparent
      linear-gradient(90deg, $really-light-grey 0%, $bt-white 100%) 0% 0%
      no-repeat padding-box;
  }

  h1 {
    margin-bottom: 15px;
  }
}

.channel-banner {
  margin: auto auto 60px;
  padding: 0 12px;
  max-width: $max_width;
  width: 92%;

  // the bs row element parent flex element
  .channel-banner-flex {
    flex-wrap: nowrap;

    @include media-breakpoint-up(xl) {
      width: 980px;
    }
  }

  .media-padding {
    flex: 0 1 auto;
    padding-right: 20px !important;
    width: auto;

    .single-channel-logo {
      height: 60px;
      width: 60px;
      @include media-breakpoint-up(sm) {
        height: 163px;
        width: 163px;
      }
    }
  }
  .channel-meta {
    flex: 1 1 auto;
    width: auto;
    padding-left: 0 !important;

    .channel-title {
      font-size: 26px;
      margin: 0 0 20px;
      @include media-breakpoint-up(sm) {
        font-size: 36px;
        margin: -4px 0 14px;
      }
      line-height: 1.2;

      .single-icon-button {
        margin-left: 30px;
        vertical-align: middle;
        white-space: nowrap;
      }
    }

    // at mobile display we need to hide the initial descrip & channel stats
    .hide-at-mobile,
    .remove-for-mobile,
    .channel-strapline {
      display: none;
    }
    .show-at-mobile {
      display: block;
    }
    @include media-breakpoint-up(sm) {
      .channel-strapline {
        display: block;
        margin: 0 0 14px;
      }
      // show the channel manage small up
      .remove-for-mobile {
        display: inline;
      }
    }
  }
  .channel-description {
    @include roboto-light;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .channel-strapline,
  .show-strap-at-sm-up {
    font-size: 18px;
    @include roboto-medium;
  }

  // the stats block general styles
  .channel-stats {
    .stat-li {
      display: inline;
      margin-right: 30px;

      .stats-icon {
        margin-right: 6px;
      }
    }
  }

  .mobile-meta-block {
    width: 100%;
    @include media-breakpoint-down(md) {
      margin-top: 10px;
    }

    @include media-breakpoint-up(sm) {
      .channel-strapline {
        display: none;
      }
    }
    .channel-stats {
      .stats-name {
        display: none;
      }
    }
  }

  .single-channel-logo-wrap {
    border: 1px solid $box-border-color-light;
    border-radius: 5px;
    display: inline-block;
    padding: 5px;
    width: auto;

    .single-channel-logo {
      border-radius: 5px;
    }
  }
  @include media-breakpoint-up(md) {
    // the bs row element parent flex element
    .channel-banner-flex {
      flex-wrap: nowrap;
      flex-direction: row;
    }
    .media-padding {
      flex: 0 1 auto;
      padding-right: 20px;
      width: auto;

      .single-channel-logo {
        height: 163px;
        width: 163px;
      }
    }
    .channel-meta {
      flex: 1 1 auto;
      width: auto;

      .channel-title {
        font-size: 36px;
      }
      // we show the block md up
      .hide-at-mobile {
        display: block;
      }
    }
    .mobile-meta-block {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    .banner-container {
      width: auto;
    }
  }
  @include media-breakpoint-up(xl) {
    .channel-meta {
      width: 400px;
    }
  }
}

@include media-breakpoint-up(md) {
  .page-banner {
    .banner-container {
      width: 100%;
    }
  }
}

.homepage-banner {
  min-height: 300px;
  height: 300px;
  width: 100%;
  position: relative;
  opacity: 1;
  z-index: 0;
  padding: 36px 0;

  .homepage-image {
    object-fit: cover;
  }

  h1 {
    margin-bottom: 10px;
  }

  @include media-breakpoint-up(sm) {
    h1 {
      width: 560px;
    }
  }

  p {
    font-size: 18px;
  }

  .hero-mask {
    background: transparent
      linear-gradient(
        90deg,
        #002244f2 0%,
        #002244b3 50%,
        #00224433 72%,
        #00224400 100%
      )
      0% 0% no-repeat padding-box;
    position: absolute;
    top: 0;
    right: 0;
    min-height: 300px;
    width: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .homepage-banner {
    min-height: 404px;
    height: 404px;

    .hero-mask {
      height: 404px;
      min-height: 404px;
      background-color: $bt-dark-blue;
    }
  }
}

.light {
  color: $bt-white;
}

.dark {
  color: $primary-text;
}
@include media-breakpoint-down(xl) {
  .bootstrap-container > div {
    display: flex;
    flex-direction: column-reverse;
  }
  .google-ads-main {
    margin-bottom: 17px;
  }
}
@include media-breakpoint-up(xl) {
  .google-ads-main {
    display: flex !important;
    align-content: stretch;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-height: none !important;
    flex-direction: column;
    flex-flow: row wrap;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1350px) {
  .media-padding {
    padding-right: 70px;
  }
}

.user-account-hero-banner {
  .banner-desc {
    @include media-breakpoint-up(lg) {
      width: 60%;
    }
  }
}
.paraspacing {
  margin-top: 20px;
}

.series-text {
  font-weight: 300;
}
.series-button-group {
  margin-top: 30px;

  // svg {
  //   color: #fff;
  //   background-color: #270;
  // }
  .success-message {
    display: inline-block;
    margin-right: 30px;
  }
}

.series-hero-banner {
  .hero-mask {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    .preview-image {
      background: transparent center center no-repeat;
      -webkit-filter: blur(4rem) grayscale(30%);
      filter: blur(4rem) grayscale(30%);
      opacity: 0.17;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      width: 100%;
      height: 100%;
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);
    }
    .gradient {
      background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 50%, $bt-white);
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  h1 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .series-text {
    font-size: 17px;
  }
  .bt-button {
    font-size: 20px !important;
  }
  .right-banner > div {
    width: 100%;
  }
  .right-banner img {
    max-width: 100%;
  }
  .seriesLogo {
    margin-top: 10px;
    text-align: right;
  }
  .image-col {
    img {
      object-fit: cover;
      margin: 0 auto;
      border-radius: 10px;
      height: 231px;
    }
    text-align: right;
  }
  .paraspacing {
    margin-top: 30px;
    .description {
      font-size: 16px;
      font-weight: 300;
    }
  }
  .bootstrap-container > div {
    flex-direction: row-reverse;
  }

  @include media-breakpoint-down(xxl) {
    h1 {
      font-size: 26px;
    }
    .right-banner {
      display: flex;
      justify-content: center; /* Horizontal center */
      align-items: center;
    }
    .image-col {
      img {
        height: 189px;
      }
    }
  }
  @include media-breakpoint-down(xl) {
    h1 {
      font-size: 22px;
    }
    .image-col {
      text-align: inherit;

      img {
        height: 152px;
        width: 100%;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .bt-button {
      width: 100%;
    }
    h1 {
      margin-top: 30px;
      &.nomargin {
        margin-top: 0;
      }
    }
    .image-col {
      img {
        height: 234px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    h1 {
      font-size: 18px;
      margin-top: 30px;
    }
    .image-col {
      width: 100%;
      img {
        width: 100%;
        height: 234px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .image-col {
      width: 100%;
      img {
        height: 111px;
      }
    }
  }
}
