@import './globalColorVars.scss';

.icon-custom-color {
  color: var(--series-primary-color, $button-primary-color);
}

.Accordian-Label {
	border: 1px solid $border-color-bright !important;
}

.outlook-icon,
.google-icon,
.apple-icon {
	color: var(--series-primary-color, $icon-grey) !important;
}

.calendatIcon {
	color: var(--series-primary-color, $button-primary-color) !important;
}